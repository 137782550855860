import React from "react";
import "./Calculator.css";
import { IoCalculatorOutline } from "react-icons/io5";
import { FaPiggyBank } from "react-icons/fa";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FaPercentage } from "react-icons/fa";
import { Link } from "react-router-dom";

const Calculator = () => {
  return (
    <div className="py-8" >
      <h1 class="text-4xl font-bold tracking-tight text-gray-900 text-center sm:text-6xl py-8">
        INVESTMENT CALCULATORS
      </h1>

      <h2 class="text-center text-xl text-gray-500 py-4">
        Our calculators make complex investment math easy! Just enter a few
        numbers and get quick, accurate answers. Save time and avoid mistakes
        with these helpful tools.
      </h2>

      {/* Cards  */}
      <div class="flex flex-wrap justify-center gap-4">
        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">SIP Calculator</h3>
          <FaPiggyBank style={{fontSize:'50px'}} />

          <p class="text-gray-600">
            Calculate the returns on your SIP investments.
          </p>
          {/* <a href="#" class="block font-semibold mt-2 py-2 px-4 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white text-shadow-md hover:bg-gradient-to-l">Calculate now</a> */}

          {/* <button type="button" class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse">
  <svg class="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
  Calculate now
</button> */}
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            <Link to="/sip">Calculate now</Link>
            
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900 flex items-center">
            EMI Calculator
          </h3>
          <IoCalculatorOutline style={{fontSize: '50px'}} />
          <p class="text-gray-600">Calculate your monthly EMI payments.</p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Lumpsum Calculator</h3>
          <FaMoneyBillAlt style={{ fontSize:'50px' }} />
          <p class="text-gray-600">
            Calculate the returns on your lumpsum investments.
          </p>
          {/* <a href="#" class="block text-indigo-600 font-semibold mt-2">Calculate now</a> */}
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Tax Calculator</h3>
          <FaPercentage style={{fontSize:"50px"}} />
          <p class="text-gray-600">
            Calculate your tax liability for the year.
            
          </p>
          {/* <a href="#" class="block text-indigo-600 font-semibold mt-2">Calculate now</a> */}
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Retirement Calculator</h3>
          <p class="text-gray-600">
            Calculate how much you need to save for retirement.
          </p>
          {/* <a href="#" class="block text-indigo-600 font-semibold mt-2">Calculate now</a> */}
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Education Calculator</h3>
          <p class="text-gray-600">
            Calculate how much you need to save for your child's education.
          </p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Insurance Calculator</h3>
          <p class="text-gray-600">Calculate how much insurance you need.</p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Loan Calculator</h3>
          <p class="text-gray-600">Calculate the interest on your loans.</p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Inflation Calculator</h3>
          <p class="text-gray-600">
            Calculate the impact of inflation on your investments.
          </p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Goal Calculator</h3>
          <p class="text-gray-600">
            Calculate how much you need to save to reach your goals.
          </p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Risk Calculator</h3>
          <p class="text-gray-600">Calculate your risk tolerance level.</p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>

        <div class="bg-white shadow rounded-lg w-72 p-4">
          <h3 class="text-xl font-bold text-gray-900">Allocation Calculator</h3>
          <p class="text-gray-600">Optimize your portfolio allocation.</p>
          <button
            type="button"
            class="block flex items-center font-bold text-lg py-3 px-6 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white hover:scale-110 transition duration-300 ease-in-out focus:outline-none animate-pulse"
          >
            <svg
              class="h-6 w-6 mr-2 animate-icon"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            Calculate now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
