const people = [
  {
    name: 'Rabindra Kumar',
    role: 'Director / HR',
    imageUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnhoUDe-okEWbgRXUiqeTC3a_FJ8YzeaHjBw&s',
  },
  // More people...
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Rapture Securities Private Limited is a Private company incorporated on 26 February 2013. It is classified as Non-government company and is registered at Registrar of Companies, Patna. Its authorized share capital is Rs. 500,000 and its paid up capital is Rs. 450,000. It's NIC code is 741 (which is part of its CIN). As per the NIC code, it is inolved in Legal, accounting, book-keeping and auditing activities; tax consultancy; market research and public opinion polling; business and management consultancy.
          </p>
        </div>
        <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
