import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const Statistic = () => {
  const [users, setUsers] = useState(0);
  const [subscribers, setSubscribers] = useState(0);
  const [downloads, setDownloads] = useState(0);
  const [products, setProducts] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  // const ref = useRef(null);
  const { inView, ref: inViewRef } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      // Reset all values to 0 when not in view
      setUsers(0);
      setSubscribers(0);
      setDownloads(0);
      setProducts(0);
    }
  }, [inView]);

  useEffect(() => {
    if (isVisible) {
      const incrementNumbers = () => {
        const incrementInterval = setInterval(() => {
          setUsers(prevUsers => {
            const newValue = prevUsers + 30;
            return newValue >= 250 ? 250 : newValue;
          });
        }, 100); // Adjust the interval for speed

        const incrementSubscribers = setInterval(() => {
          setSubscribers(prevSubscribers => {
            const newValue = prevSubscribers + 50;
            return newValue >= 2500 ? 2500 : newValue;
          });
        }, 20); // Adjust the interval for speed

        const incrementDownloads = setInterval(() => {
          setDownloads(prevDownloads => {
            const newValue = prevDownloads + 1;
            return newValue >= 35 ? 35 : newValue;
          });
        }, 20); // Adjust the interval for speed

        const incrementProducts = setInterval(() => {
          setProducts(prevProducts => {
            const newValue = prevProducts + 1;
            return newValue >= 10 ? 10 : newValue;
          });
        }, 70); // Adjust the interval for speed

        return () => {
          clearInterval(incrementInterval);
          clearInterval(incrementSubscribers);
          clearInterval(incrementDownloads);
          clearInterval(incrementProducts);
        };
      };

      incrementNumbers();
    }
  }, [isVisible]);

  return (
    <div>
      <section className="text-gray-600 body-font bg-indigo-500 ">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4 text-center" ref={inViewRef}>
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{users}Cr+</h2>
              <p className="leading-relaxed text-white">Asset Under Management</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{subscribers}+</h2>
              <p className="leading-relaxed text-white">Happy Clients</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{downloads}+</h2>
              <p className="leading-relaxed text-white">Awards</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{products}+</h2>
              <p className="leading-relaxed text-white">Years of Experience</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Statistic;
