import React from 'react'
import './Services.css'
const Services = () => {
  return (
    <div className='bg-indigo-500 center' >
          <div class="page-wrapper">
          <h2 className=" center mb-6 font-sans text-4xl font-bold tracking-tight text-white sm:text-6xl sm:leading-none">
          PRODUCT OFFERINGS
            </h2>

    <div class="container">
        <ul class="infographic-cards">
            <li class="color-1">
                <i class="fa-regular fa-lightbulb"></i>
                <h5>MUTUAL FUND</h5>
                <h6>Click to know more</h6>
                <div class="number-box">
                    01
                   </div>
            </li>
            <li class="color-2">
                <i class="fa-solid fa-wifi"></i>
                <h5>Fixed Deposit</h5>
                <h6>Click to know more</h6>

                <div class="number-box">
                    02
                   </div>
            </li>
            <li class="color-3">
                <i class="fa-regular fa-user"></i>
                <h5>Insurance</h5>
                <h6>Click to know more</h6>

                <div class="number-box">
                    03
                   </div>
            </li>
            <li class="color-4">
                <i class="fa-brands fa-codepen"></i>
                <h5>Government Bonds</h5>
                <h6>Click to know more</h6>

                <div class="number-box">
                    04
                   </div>
            </li>
            
        </ul>
       
    </div>


    <div class="container">
        <ul class="infographic-cards">
            <li class="color-1">
                <i class="fa-regular fa-lightbulb"></i>
                <h5>SIP Investment </h5>
                <h6>Click to know more</h6>
                <div class="number-box">
                    05
                   </div>
            </li>
            <li class="color-2">
                <i class="fa-solid fa-wifi"></i>
                <h5>Equity Broking</h5>
                <h6>Click to know more</h6>

                <div class="number-box">
                    06
                   </div>
            </li>
            <li class="color-3">
                <i class="fa-regular fa-user"></i>
                <h5>NRI Investment</h5>
                <h6>Click to know more</h6>

                <div class="number-box">
                    07
                   </div>
            </li>
            <li class="color-4">
                <i class="fa-brands fa-codepen"></i>
                <h5>Corporate Deposite</h5>
                <h6>Click to know more</h6>

                <div class="number-box">
                    08
                   </div>
            </li>
            
        </ul>
       
    </div>
   </div>

    </div>
  )
}

export default Services