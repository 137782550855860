import React from 'react'
import Home from './components/Home'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import ContactUs from './components/pages/ContactUs'
import AboutUs from './components/pages/AboutUs'
import Navbar from './components/Navbar'
import Calculator from './components/Calculator'
import Sip from './components/calculators/sip'

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Calculator" element={<Calculator />} />
        <Route path="/sip" element={<Sip />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App