import React, { useState } from 'react';
import Button from './Buttons';
import { IonIcon } from '@ionic/react';
import { logoIonic, close, menu } from 'ionicons/icons';
// import Typed from 'react-typed'
import './Navbar.css';
// import rapture_logo from './assets/rapture_logo.png'


import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import rapture_logo from './assets/rapture_logo.png'


const Navbar = () => {

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Contact', href: 'ContactUs' },
    // { name: 'About Us', href: 'AboutUs' },
    { name: 'About Us', href: 'https://www.zaubacorp.com/company/RAPTURE-CONSULTANTS-PRIVATE-LIMITED/U74140BR2013PTC019916' },
    { name: 'Calculator', href: 'Calculator' },
  ]
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

//   const [open, setOpen] = useState(false);

//   const handleButtonClick = () => {
//     setOpen(!open);
//   };

//   const Links = [
//     { name: 'HOME', link: '/' },
//     { name: 'SERVICE', link: '/' },
//     { name: 'ABOUT', link: '/' },
//     { name: 'CONTACT', link: '/' },
//   ];

  return (
//     <div className='shadow-md w-full fixed  top-0 left-0 mainnav'>
//       <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
//         <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
//           <span className='text-3xl text-indigo-600 mr-1 pt-2'>
//             {/* <IonIcon icon={logoIonic} /> */}
//               <img src={rapture_logo} alt="logo" className="h-11 w-26"/>
//           </span>
//                         {/* <Typed
//                 className="heading-typed"
//                 strings={[
//                   'Rapture Securities Pvt. Ltd.',
//                   'Your Way To Success',
//                 ]}
//                 typeSpeed={40}
//                 backSpeed={50}
//                 loop

//               /> */}

//         </div>
//         {/* show about us page contact us */}
//         <div className='hidden md:flex md:items-center md:pb-0 pb-12'>
//     {Links.map((link) => (
//         <div key={link.name} className='md:ml-8 text-xl md:my-0 my-7'>
//             <a
//                 href={link.link}
//                 className='text-gray-800 hover:text-gray-400 duration-500'
//             >
//                 {link.name}
//             </a>
//         </div>
//     ))}
//     <div className='md:ml-8 text-xl md:my-0 my-7'>
//         <Button >LOGIN </Button> 
//     </div>
// </div>

//         {/* show about us page contact us */}

//         <div
//           onClick={handleButtonClick}
//           className='text-3xl absolute right-8 top-6 cursor-pointer block md:hidden'
//         >
//           <IonIcon icon={open ? close : menu} />
//         </div>

//         {open && (
//           <ul className='md:flex md:items-center md:pb-0 absolute md:static bg-white md:z-auto left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in top-20'>
//             {Links.map((link) => (
//               <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7'>
//                 <a
//                   href={link.link}
//                   className='text-gray-800 hover:text-gray-400 duration-500 '
//                 >
//                   {link.name}
//                 </a>
//               </li>
//             ))}
//             {/* <li className='md:ml-8 text-xl md:my-0 my-7'>
//               <Button>Get Started</Button>
//             </li> */}
//           </ul>
//         )}
//       </div>
//     </div>

<div>
<header className=" inset-x-0 top-0 z-50 bg-slate-50 opacity-60 ">
        {/* Adding extra colors  */}
        {/* colourful  */}
        {/* <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}


        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                src={rapture_logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
</div>
  );
};

export default Navbar;
