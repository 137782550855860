import React from 'react'
import './sip.css'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState,useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);

const Sip = () => {

    const [sliderValue, setSliderValue] = useState(500);
    const [expreturn, setExpreturn] = useState(12);
    const [Timeperiod, setTimeperiod] = useState(12);
    const [monthlyinvValue, setmonthlyinvValue] = useState('');
    const [FutVal, setFutVal] = useState('');
    const [EstimatedReturn, setEstimatedReturn] = useState('');
    const [InvestedAmount, setInvestedAmount] = useState('');
    
    const [chartInvested ,setchartInvested ] = useState();
    const [chartReturns, setchartReturns] = useState();



    const handleInputChange = (event) => {
        setmonthlyinvValue(event.target.value);
        setSliderValue(event.target.value);
      };

      let TotalValue, EstimatedReturns, InvestmentAmount;
        let totalmoney;
      let TxtFutureValue;
      useEffect(() => {    
    //  TotalValue = sliderValue*(((Math.pow(1+(expreturn/100),Timeperiod*12)-1)/(expreturn/100))).toFixed(2);
    //  EstimatedReturns = (sliderValue*(((Math.pow(1+(expreturn/100),Timeperiod*12)-1)/(expreturn/100)))-(sliderValue*Timeperiod*12)).toFixed(2);
     InvestmentAmount = sliderValue*Timeperiod*12;
    //  console.log("Inv amount "+InvestmentAmount)
     console.log("Estimated Returns % "+expreturn)
    //  console.log("Timeperiodd" + Timeperiod)
     TotalValue = sliderValue * ((Math.pow(1 + (expreturn / 100), Timeperiod * 12) - 1)) / (expreturn / 100);
    //  console.log("Total val "+TotalValue)
     totalmoney= sliderValue*Timeperiod*12;
    console.log("Total money "+totalmoney)
    let futval = sliderValue * ((Math.pow(1 + (expreturn / 100), Timeperiod * 12) - 1)) / (expreturn / 100);
    console.log("Use Effect "+sliderValue, expreturn, Timeperiod)
    let num=1+(expreturn/(expreturn*100));
    let pow = Math.pow(num,Timeperiod*12);
    let top = (pow -1)/(expreturn/(expreturn*100));
     TxtFutureValue = top * (1+(expreturn/ (expreturn*100))) * sliderValue;
    //  setFutVal
    let FinalFutureValue = TxtFutureValue.toFixed(2);
    setFutVal(FinalFutureValue);


    setEstimatedReturn(Math.abs(totalmoney-FinalFutureValue).toFixed(2));
    setInvestedAmount((sliderValue*Timeperiod*12).toFixed(2));

    console.log("formula test exp % is "+ expreturn )

    setchartInvested(totalmoney);
    setchartReturns(FinalFutureValue);


      }, [sliderValue, expreturn, Timeperiod])
      


     

    

    const handleSliderChangetimeperiod = (event, newValue) => {
        setTimeperiod(newValue);
        console.log('Time Period:', newValue); // Log the new value
      }
      
    const handleSliderChangeexpreturn = (event, newValue) => {
        setExpreturn(newValue);
        console.log('Expected Return:', newValue); // Log the new value
      }
       

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        console.log('Monthly Investment:', newValue); // Log the new value
      };

      const handleInputChange2 = (event) => {
        setExpreturn(event.target.value);
        setExpreturn(event.target.value);
      }
      const handleInputChange3 = (event) => {
        setTimeperiod(event.target.value);
        setTimeperiod(event.target.value);
      }








  return (
    <div>
      <h1 class="text-4xl font-bold tracking-tight text-left sm:text-6xl py-8 px-2" style={{ color: "#5300f4" }}>
        SIP Calculator
        </h1>
        <div class="text-left px-2">
        <p class="text-lg text-gray-900">
        Calculate returns on your investments with our online SIP calculator.
        </p>
        </div>
        <div className="main-container">
            <div className="left">

            <div className="userinp1">
            <h1 className='mon-inv' >Monthly Investment</h1> 

            <input type="text" className='myinp' value={sliderValue} onChange={handleInputChange} />
            </div>
            <h1 className='mon-inv-val' >₹{sliderValue}</h1>
            <Box sx={{ width: { xs: 300, md: 600 } }} >
            <Slider
              defaultValue={100}
              value={sliderValue} // Use the state variable to track the value
              onChange={handleSliderChange}
              aria-label="Investment Amount"
              valueLabelDisplay="auto"
              min = {500}
                max={1000000}
              sx={{
                '& .MuiSlider-rail': {
                  backgroundColor: '#5300f4', // Set rail color
                },
                '& .MuiSlider-thumb': {
                  backgroundColor: '#5300f4', // Set thumb color
                },
              }}
            />
          </Box>

              <div className="userinp1">
          <h1 className='mon-inv' >Expected return rate (P.A)</h1>
          <input type="text" className='myinp' value={expreturn} onChange={handleInputChange2} />

              </div>
            <h1 className='mon-inv-val' >{expreturn}%</h1>
            <Box sx={{ width: { xs: 300, md: 600 } }}>
            <Slider
              defaultValue={12}
              value={expreturn} // Use the state variable to track the value
              onChange={handleSliderChangeexpreturn}
              aria-label="Investment Amount"
              valueLabelDisplay="auto"
              max={30} 
            //   step={0.1}
              sx={{
                '& .MuiSlider-rail': {
                  backgroundColor: '#5300f4', // Set rail color
                },
                '& .MuiSlider-thumb': {
                  backgroundColor: '#5300f4', // Set thumb color
                },
              }}
            />
          </Box>

          {/* time period  */}
          <div className="userinp1">
          <h1 className='mon-inv' >Time Period</h1>
          <input type="text" className='myinp' value={Timeperiod} onChange={handleInputChange3} />
          </div>

            <h1 className='mon-inv-val' >{Timeperiod}Y</h1>
            <Box sx={{ width: { xs: 300, md: 600 } }}>
            <Slider
              defaultValue={12}
              value={Timeperiod} // Use the state variable to track the value
              onChange={handleSliderChangetimeperiod}
              aria-label="Investment Amount"
              valueLabelDisplay="auto"
              max={100} 
              sx={{
                '& .MuiSlider-rail': {
                  backgroundColor: '#5300f4', // Set rail color
                },
                '& .MuiSlider-thumb': {
                  backgroundColor: '#5300f4', // Set thumb color
                },
              }}
              
            />
          </Box>

          
            </div>
            <div className="right">
                <div className="rightbox">
                {/* <Doughnut 
                data={dummyData}
                /> */}

<Doughnut 
  data={{
    labels: ['Investment', 'Returns'],
    datasets: [
      { 
        // data: [EstimatedReturn,InvestedAmount], // Use state variables
        // data: [chartInvested,chartReturns], //!main Use state variables
        data: [chartInvested,chartReturns], // Use state variables
        backgroundColor: ['#5300f4', '#9089fc', ],
        hoverBackgroundColor: ['#4285F4', '#7FDBFF',],
        backgroundColor: ['#5300f4', '#9089fc',], // Customize colors
        hoverBackgroundColor: ['#4285F4', '#7FDBFF',], // Customize hover colors
       
      },
    ],
  }}
/>

                    <div className="rightdata">
                    <h1>Investment Amount</h1> 
                    <h1>₹{InvestedAmount}</h1>
                    </div> 
                    <div className="rightdata">
                    <h1>Future Value</h1> 
                    {/* correct formula  */}
                    {/* sliderValue * ((Math.pow(1 + (expreturn /(expreturn*100)), Timeperiod * 12) - 1)) / (expreturn /(expreturn* 100))*(1+expreturn/(expreturn*100)) */}
                    {/* <h1>₹{sliderValue * ((Math.pow(1 + (expreturn /(expreturn*100)), Timeperiod * 12) - 1)) / (expreturn /(expreturn* 100))*(1+expreturn/(expreturn*100))}</h1> */}
                    <h1>{FutVal}</h1>
                    </div>
                    <div className="rightdata">
                    <h1>Estimated Returns</h1>
                    <h1>₹{EstimatedReturn}</h1>
                    </div>  
                </div>
            </div>
        </div>

    </div>
  )
}

export default Sip