import React from 'react'
import './Brand.scss'
import { motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';


const Brand = () => {

    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    // const { inView } = useInView({ threshold: 0.5 }); // Observe when 50% of the element is visible
    const { ref: inViewRef, inView } = useInView({ threshold: 0.3 });
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      }
      else {
        setIsVisible(false);
      }
    }, [inView]);
  
  return (
    <section className='py-2' >
    <motion.div className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl  my-3 text-center"
     initial = {{ opacity:0, y:"+100%"}}
     // animate={{ opacity:1, y: 0 }}
   animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: "+100%" }}

     transition={{ duration: 1, ease: "easeInOut" }} 
     ref={inViewRef}
    >
            Trending Mutual Funds
            </motion.div>
<div class="slider">
	<div class="slide-track">
		<div class="slide">
			<img src="https://login.whiteoakamc.com/Investorlogin/images/logo.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://wp.wealthzi.com/wp-content/uploads/2021/06/1538139609_pWFHYC_cropped_94_-11234.png" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://upload.wikimedia.org/wikipedia/commons/2/27/Logo_Ben_Head.jpg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://finbridgeexpo.com/wp-content/uploads/2022/11/SBI-Mutual-Fund-Logo.jpg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://tigress.capital/wp-content/uploads/2023/01/MutualFunds1.1-1024x202.webp" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://images.moneycontrol.com/static-mcnews/2018/06/UTI-MF-final.png?impolicy=website&width=1600&height=900" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQcMdHVIOnXAgP5dvGXayhIj47H-Jw-iwmCWwj5rtjM1qlbI5rdx2lUc2erI1ykXnjQg&usqp=CAU" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="https://static.wikia.nocookie.net/logopedia/images/a/ae/Reliance_mf.jpeg" height="100" width="250" alt="" />
		</div>
	</div>
</div>
</section>
  )
}

export default Brand