import React from 'react'
import './Brand2.scss'
const Brand2 = () => {
    return (
        <section className='py-2' >
    <div class="slider2">
        <div class="slide-track2">
            <div class="slide">
                <img src="https://login.whiteoakamc.com/Investorlogin/images/logo.svg" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://wp.wealthzi.com/wp-content/uploads/2021/06/1538139609_pWFHYC_cropped_94_-11234.png" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://upload.wikimedia.org/wikipedia/commons/2/27/Logo_Ben_Head.jpg" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://finbridgeexpo.com/wp-content/uploads/2022/11/SBI-Mutual-Fund-Logo.jpg" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://tigress.capital/wp-content/uploads/2023/01/MutualFunds1.1-1024x202.webp" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://images.moneycontrol.com/static-mcnews/2018/06/UTI-MF-final.png?impolicy=website&width=1600&height=900" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQcMdHVIOnXAgP5dvGXayhIj47H-Jw-iwmCWwj5rtjM1qlbI5rdx2lUc2erI1ykXnjQg&usqp=CAU" height="100" width="250" alt="" />
            </div>
            <div class="slide">
                <img src="https://static.wikia.nocookie.net/logopedia/images/a/ae/Reliance_mf.jpeg" height="100" width="250" alt="" />
            </div>
        </div>
    </div>
    </section>
      )
}

export default Brand2