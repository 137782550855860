import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'


const posts = [
  {
    id: 1,
    title: 'Seed Your Future: The Mutual Fund Miracle Grow',
    href: '#',
    description:
      'Investing in mutual funds is like planting a seed. You give it a little water and sunshine, and over time, it grows into a beautiful tree 🌳 ',
    date: 'Jan 16, 2023',
    datetime: '2020-03-16',
    category: { title: 'Investing', href: '#' },
    author: {
      name: 'Rabindra Kumar',
      role: 'Director',
      href: '#',
      imageUrl:
        'https://img.freepik.com/free-vector/golden-crown-classic-decorative-icon_18591-82025.jpg?t=st=1714816355~exp=1714819955~hmac=5428eff55c771c17d818099e713fd4eeee42dc814c7a760da7b41ea7de4ae737&w=1060',
    },
  },
  {
    id: 2,
    title: 'Hive of Wealth: Let Mutual Funds Do the Heavy Lifting While You Fly Free',
    href: '#',
    description:
'Mutual funds are the perfect choice for all the busy bees who want to grow their wealth without buzzing around all day 🐝',
    date: 'Mar 23, 2021',
    datetime: '2020-03-16',
    category: { title: 'Wealth building', href: '#' },
    author: {
      name: 'Prabhu Nath Singh',
      role: 'Director',
      href: '#',
      imageUrl:
      'https://img.freepik.com/free-vector/golden-crown-classic-decorative-icon_18591-82025.jpg?t=st=1714816355~exp=1714819955~hmac=5428eff55c771c17d818099e713fd4eeee42dc814c7a760da7b41ea7de4ae737&w=1060',

    },
  },
  {
    id: 3,
    title: 'Beyond Luck, Beyond Gamble:Investing with Skill, Not Chance',
    href: '#',
    description:
"Don't throw random ingredients in a pot. Do your research, blend flavors wisely, and simmer with patience for a delicious financial feast 🎊",
    date: 'Oct 8, 2023',
    datetime: '2020-03-16',
    category: { title: 'Financial planning', href: '#' },
    author: {
      name: 'Madan Prasad',
      role: 'Additional Director',
      href: '#',
      imageUrl:
      'https://img.freepik.com/free-vector/golden-crown-classic-decorative-icon_18591-82025.jpg?t=st=1714816355~exp=1714819955~hmac=5428eff55c771c17d818099e713fd4eeee42dc814c7a760da7b41ea7de4ae737&w=1060',

    },
  },
 
  // More posts...
]

export default function SecondPage() {


  // const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
    else {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <motion.div className="mx-auto max-w-2xl lg:mx-0"
        initial = {{ opacity:0, y:"+100%"}}
        // animate={{ opacity:1, y: 0 }}
        // animate={isVisible2 ? { opacity: 1, y: 0 } : { opacity: 0, y: "+100%" }}
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: "+100%" }}
        transition={{ duration: 1, ease: "easeInOut" }} 
        ref={inViewRef}
        >
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Some Insider tips</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn how to grow your wealth with our expert advice.
          </p>
        </motion.div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <a
                  href={post.category.href}
                  className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                >
                  {post.category.title}
                </a>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <a href={post.author.href}>
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600">{post.author.role}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
